<template>
  <component :is="rowData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="rowData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching offer data
      </h4>
      <div class="alert-body">
        No offer found with this event id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'offers'}"
        >
          Offers List
        </b-link>
        for other offers.
      </div>
    </b-alert>
    <b-form @submit.prevent="onUpdate">
      <b-row>
        <b-col md="6">
          <b-form-group
              label="Title En"
              label-for="mc-title-en"
          >
            <b-form-input
                v-model="rowData.title_en"
                id="mc-title-en"
                placeholder="Title En"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Title Ar"
              label-for="mc-title-ar"
          >
            <b-form-input
                v-model="rowData.title_ar"
                id="mc-title-ar"
                placeholder="Title Ar"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
              label="Offer Code"
              label-for="offer_code"
          >
            <b-form-input
                id="offer_code"
                v-model="rowData.offer_code"
                autofocus
                trim
                placeholder="Offer Code"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Offer Value"
              label-for="offer_value"
          >
            <cleave
                id="number"
                v-model="rowData.offer_value"
                class="form-control"
                :raw="false"
                min="1"
                autofocus
                :options="mask.number"
                placeholder="Offer Value"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Offer Type -->
      <b-row>
        <b-col md="6">
          <b-form-group
              label="Offer Type"
              label-for="offer_type"
          >
            <v-select
                id="offer_type"
                v-model="rowData.offer_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="offerTypeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Courses"
              label-for="courses"
          >
            <v-select
                v-model="rowData.selected_courses"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="title"
                @input="flag = 1"
                :reduce="val => val.value"
                :options="courses"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Expiration Date -->
      <b-row>
        <b-col md="12">
          <b-form-group
              label="Expiration Date"
              label-for="expiration_date"
          >
            <b-form-datepicker
                v-model="rowData.expiration_date"
                :date-disabled-fn="dateDisabled"
                id="mc-expire-date"
                placeholder="Expire Date"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
              label="Is Active"
              label-for="is_active"
          >
            <b-form-checkbox
                id="is_active"
                v-model="rowData.is_active"
                :checked=rowData.is_active
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <!-- Flag -->
          <file
              title="Select Event Image"
              :default_place_holder_src="rowData.image"
              @file_uploaded="({media})=>{rowData.image= media.url; imageId = media.id}"
          ></file>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import offerStoreModule from '../offerStoreModule'
import {BAlert, BButton, BCard, BCol, BFormDatepicker, BForm, BFormCheckbox, BFormGroup, BFormInput, BLink, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from "vue-cleave-component";

export default {
  components: {
    BCard,
    BFormDatepicker,
    Cleave,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,
    flatPickr
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      courses: [],
      lists: [],
      mask: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'wan',
          delimiter: '',
          numericOnly: true,
        },
      },
    }
  },
  methods:{
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      const month = date.getMonth();
      const year = date.getFullYear();

      const current = new Date();
      const currentDay = current.getDate()
      const currentMonth = current.getMonth()
      const currentYear = current.getFullYear()

      // Return `true` if the date should be disabled
      if (year < currentYear){
        return true
      }
      if (year <= currentYear){
        if (month <= currentMonth){
          if (day <= currentDay && year <= currentYear){
            return true
          }else if (day >= currentDay && month < currentMonth){
            return true
          }
        }
      }
    },
  },
  created() {
    this.$http.get('/all-courses').then(response => {
      let that = this
      this.lists = response.data.data.map(function(item){
        that.courses.push({
          title : item.title_en,
          value: item.id+""
        })
      })
    })
  },
  setup() {
    const blankData = {
      title_en: '',
      title_ar: '',
      selected_courses: null,
      is_active: true,
      event_url: '',
      image: null,
      image_id: null,
    }
    const imageId = ref(null);
    const flag = ref(0);
    const toast = useToast()
    const offerTypeOptions = ref([
      {
        title: 'Percentage',
        value: 1
      },
      {
        title: 'Value',
        value: 2
      },
    ])
    const rowData = ref(JSON.parse(JSON.stringify(blankData)))
    const OFFER_APP_STORE_MODULE_NAME = 'app-offer'
    // Register module
    if (!store.hasModule(OFFER_APP_STORE_MODULE_NAME)) store.registerModule(OFFER_APP_STORE_MODULE_NAME, offerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OFFER_APP_STORE_MODULE_NAME)) store.unregisterModule(OFFER_APP_STORE_MODULE_NAME)
    })
    store.dispatch('app-offer/show', {id: router.currentRoute.params.id})
        .then(response => {
          rowData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            rowData.value = undefined
          }
        })

    const onUpdate = () => {
      let data = {
        title_en: rowData.value.title_en,
        title_ar: rowData.value.title_ar,
        is_active: rowData.value.is_active,
        expiration_date: rowData.value.expiration_date,
        offer_value: rowData.value.offer_value,
        offer_type: rowData.value.offer_type.value,
        offer_code: rowData.value.offer_code,
        selected_courses: rowData.value.selected_courses
      }
      if (imageId.value != null) {
        data.image = imageId.value
      }
      if (flag.value == 0){
        data.selected_courses = []
      }
      store.dispatch('app-offer/update', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'offers'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Offer Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {

          })
    }
    return {
      onUpdate,
      rowData,
      imageId,
      flag,
      offerTypeOptions
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
